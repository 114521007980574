import { getFullClientURL } from "../functions";
export default {
    data() {
        return {
            showIframe: false,
            iframeSrc: ""
        };
    },
    mounted() {
        this.handleWEMWidgetClick();
    },
    methods: {
        handleWEMWidgetClick() {
            let clientRegion = getFullClientURL();
            this.iframeSrc = `${clientRegion}/crm/embeddablePane.html?component=wemActivity`;
            this.showIframe = true;
        }
    }
};
