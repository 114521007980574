/* eslint-disable prefer-const */
let scriptId = "";
let logPostfix = "";
let makeCallFromScriptId;
let trackData = null;
let postMessageTimeoutId = null;
let checkCountAddAssociation = 0;
let languageTag = "";
let jsonSettingData = [];
let makeCallFromOtherTabPageInfo = null;
let sideBarCheckValue = 0;
let noResponseTimeout;
let targetCountForSession = 2;
let counterForSession = 0;
let javasciptInitializeTime = new Date();
let loginPageLoadedTime = null;
let screenpopPageLoadStartTime = null;
let screenpopPageLoadFinishTime = null;
let getEnvironmentFailCounter = 0;
let isClick2Act = false;
let currentSession = "session-id-0";
let recordNewInteraction = null;
let flagNewRecordPop = false;
let setNewInteractionBelongToNewTab = false;
let newPopRecordNotSaved = false;
let flagCallForScreenpop = false;
let processingInteractionId = null;
let searchInProgress = null;
let screenpopForPhonecall = [];
let screenpopForChat = [];
let screenpopForEmail = [];
let screenpopForCallback = [];
let screenpopForMessage = [];
let typingTimer;
let doneTypingInterval = 1000;
let typingTimerForQueue;
let doneTypingIntervalForQueue = 1000;
let delayTimeForHideCustomDial = 0;
let nameOrNumberFocused = false;
let updateContextAttemptCount = 0;
let resetCustomAttributeValue = [];
let organizationId = "";
let log = "";
const variables = {
    scriptId,
    logPostfix,
    makeCallFromScriptId,
    trackData,
    postMessageTimeoutId,
    checkCountAddAssociation,
    languageTag,
    jsonSettingData,
    makeCallFromOtherTabPageInfo,
    sideBarCheckValue,
    noResponseTimeout,
    targetCountForSession,
    counterForSession,
    javasciptInitializeTime,
    loginPageLoadedTime,
    screenpopPageLoadStartTime,
    screenpopPageLoadFinishTime,
    getEnvironmentFailCounter,
    isClick2Act,
    currentSession,
    recordNewInteraction,
    flagNewRecordPop,
    setNewInteractionBelongToNewTab,
    newPopRecordNotSaved,
    flagCallForScreenpop,
    processingInteractionId,
    searchInProgress,
    screenpopForPhonecall,
    screenpopForChat,
    screenpopForEmail,
    screenpopForCallback,
    screenpopForMessage,
    typingTimer,
    doneTypingInterval,
    typingTimerForQueue,
    doneTypingIntervalForQueue,
    delayTimeForHideCustomDial,
    nameOrNumberFocused,
    updateContextAttemptCount,
    resetCustomAttributeValue,
    organizationId,
    log
};
export default variables;
