export function debounce(func, wait, immediate) {
    let timeout;
    return function () {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const context = this, 
        // eslint-disable-next-line prefer-rest-params
        args = arguments;
        const later = function () {
            timeout = null;
            if (!immediate)
                func.apply(context, args);
        };
        const callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow)
            func.apply(context, args);
    };
}
export function wait(ms) {
    return new Promise((res) => setTimeout(res, ms));
}
