/* eslint-disable prefer-const */
let ciLoaded = false;
let loadScriptsCounter = 0;
let CrmOrigin = "";
const variables = {
    ciLoaded,
    loadScriptsCounter,
    CrmOrigin
};
export default variables;
