/* eslint-disable prefer-const */
let newOutboundInteraction = false;
let cachedPopConfig = null;
let currentInteractionForTracking = null;
let currentInteractionObjectForTrackingProcesscallLog = null;
let lastInteractionID = "";
let isScreenpopOriginTab = false;
let cachedQueuePopConfig = {};
const variables = {
    newOutboundInteraction,
    cachedPopConfig,
    currentInteractionForTracking,
    currentInteractionObjectForTrackingProcesscallLog,
    lastInteractionID,
    isScreenpopOriginTab,
    cachedQueuePopConfig
};
export default variables;
