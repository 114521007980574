import EventBus from "../interactionDetails/eventBus";
import { useInteractionStore } from "../stores/interactions";
import Interaction from "./Interaction.vue";
import { NEmpty } from "naive-ui";
const eventBus = new EventBus();
export default {
    name: "InteractionDetails",
    components: { Interaction, NEmpty },
    data() {
        return {
            interactionStore: useInteractionStore()
        };
    },
    computed: {
        interactions() {
            return this.interactionStore.all;
        }
    },
    methods: {
        addInteraction(interaction) {
            this.interactionStore.add(interaction);
        },
        removeInteraction(interactionId) {
            this.interactionStore.remove(interactionId);
        },
        patchInteraction(interaction) {
            this.interactionStore.update(interaction);
        }
    },
    mounted() {
        eventBus.init();
        eventBus.$on("interaction-add", (evt) => {
            this.addInteraction(evt.interaction);
        });
        eventBus.$on("interaction-disconnect", (evt) => {
            this.removeInteraction(evt.id);
        });
        eventBus.$on("interaction-change", (evt) => {
            const interaction = evt.interaction.new;
            this.patchInteraction(interaction);
        });
    }
};
