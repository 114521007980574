import { checkInteractionState, checkInteractionTypeToHoldActivityTab, createAssociationObject, getActivityData, getCurrentInteractionId, getDefaultfieldsValues, getDurationIfMessagingInteraction, getInteractionActivityType, getItemBasedOnInteractionId, getScreenpopFunctionArrayinteraction, getStorageItem, initilizeMappingDetectCreate, isCIFV2, isEmailAddress, isGuid, isInteractionForCustomActivity, log, processingBasicActivityValues, removeItemDataOnInteractionId, returnErrorMessageForApiFailed, saveChatTranscriptToActivity, saveMessagingTranscriptToActivity, searchAndOpenRecords, sendNotification, setActivityData, setActivityParties, setInteractionStateForSessionSwitched, setItemDataOnInteractionId, setMappingData, setRegardingRelationship, shouldCreateActivityOnUserSelection, shouldPreventLogUpdateAfterWrapUp, upsertActivity } from "./functions";
import Constant from "./variables/constants";
import Global from "./variables/global";
import SupportVariable from "./variables/support";
import $ from "jquery";
export function searchForEntity(interaction, data) {
    if (isEmailAddress(data)) {
        return searchForEntityBySearchType2(interaction, data, "email");
    }
    else {
        return searchForEntityBySearchType2(interaction, data, "phone");
    }
}
async function searchForEntityBySearchType2(interaction, data, searchType) {
    const screenpopFunctionArray = getScreenpopFunctionArrayinteraction(interaction);
    SupportVariable.allResults = [];
    try {
        await Promise.all(screenpopFunctionArray.map((v) => v(data, searchType)));
    }
    catch (e) {
        log(returnErrorMessageForApiFailed(e));
    }
    return SupportVariable.allResults;
}
export function searchEntity(entityName, entityIdColumn, queryParameters) {
    const deferred = $.Deferred();
    const searchResult = [];
    searchAndOpenRecords(entityName, queryParameters, true)
        .done(function (results) {
        if (results != "{}") {
            results = JSON.parse(results);
            for (let i = 0; i < Object.keys(results).length; i++) {
                searchResult.push({
                    recordid: results[i][entityIdColumn],
                    recordType: entityName
                });
            }
        }
        SupportVariable.allResults = SupportVariable.allResults.concat(searchResult);
        deferred.resolve(searchResult);
    })
        .fail(function (error) {
        error = returnErrorMessageForApiFailed(error);
        log(error);
        deferred.resolve(searchResult);
    });
    return deferred.promise();
}
export function createAndUpdateActivity(interaction, callLog, eventName) {
    const deferred = $.Deferred();
    try {
        const associationObj = createAssociationObject(interaction, callLog);
        const direction = associationObj.direction;
        let isUsingExistingActivity = false;
        if (interaction.id != null && interaction.id != undefined) {
            let entity = {};
            if (eventName.toLowerCase() == "updatedisposition" && callLog) {
                entity = callLog;
            }
            let activityId = null;
            let userSelectedActivityOpenCallLog = false;
            //if user selected creation
            if (shouldCreateActivityOnUserSelection() && eventName != null && eventName.toString().trim().toLowerCase() == "opencalllog") {
                userSelectedActivityOpenCallLog = true;
            }
            let entityName = null;
            const entityJsonData = getInteractionActivityType(interaction);
            entityName = entityJsonData.entityName;
            //Call logs with | indiciate that an activity has already been saved.  Call Logs without the | but still have a value indicates this is a user selected activity
            //and the value is set to the interactionid.
            if (callLog.id != undefined && callLog.id.includes("|")) {
                const bits = callLog.id.split("|");
                activityId = bits[1];
            }
            else if (callLog.attributes && callLog.attributes.is_activityid && callLog.attributes.is_activityid.trim().toLowerCase() != "new" && !isGuid(callLog.attributes.is_activityid)) {
                sendNotification("Something went wrong, cannot create activity.", "error");
                return;
            }
            else if (callLog.attributes && callLog.attributes.is_activityid && isGuid(callLog.attributes.is_activityid)) {
                entity = processingBasicActivityValues(activityId, interaction, entity, callLog, entityName);
                activityId = callLog.attributes.is_activityid;
                isUsingExistingActivity = true;
            }
            else {
                const storedActivityId = getActivityData(interaction.id);
                if (storedActivityId != null && !storedActivityId.includes(interaction.id)) {
                    activityId = storedActivityId.substr(storedActivityId.indexOf("|") + 1);
                }
                else if (shouldCreateActivityOnUserSelection() && storedActivityId) {
                    activityId = storedActivityId.substr(storedActivityId.indexOf("|") + 1);
                }
            }
            if (eventName.toString().trim().toLowerCase() == "interactiondisconnected") {
                let d = "";
                if (Object.hasOwn(interaction, "interactionDurationSeconds") && interaction.interactionDurationSeconds != undefined) {
                    d = parseInt(interaction.interactionDurationSeconds).toString();
                }
                else {
                    d = "0";
                }
                if (isInteractionForCustomActivity(interaction)) {
                    if (getDefaultfieldsValues("actualstartend", entityName)) {
                        entity.actualend = new Date().toISOString();
                    }
                }
                else {
                    entity.actualend = new Date().toISOString();
                }
                if (!isInteractionForCustomActivity(interaction)) {
                    entity.isgc_durationseconds = d;
                    if (d == "0") {
                        entity.isgc_duration = "00:00:00";
                    }
                    else {
                        entity.isgc_duration = getDurationIfMessagingInteraction(interaction, d);
                    }
                }
                //if (shouldSaveTranscript(interaction)) {
                //    saveMessagingTranscriptToActivity(interaction, activityId);
                //}
            }
            entity = processingBasicActivityValues(activityId, interaction, entity, callLog, entityName);
            const initilizeMappingDetectCreateObject = initilizeMappingDetectCreate(interaction, entity, activityId, associationObj, direction, entityName);
            const entitySchemaName = initilizeMappingDetectCreateObject.entitySchemaName;
            const isCreate = initilizeMappingDetectCreateObject.isCreate;
            const entityMappingdata = initilizeMappingDetectCreateObject.entityMappingdata;
            entity = initilizeMappingDetectCreateObject.entity;
            if (isUsingExistingActivity) {
                //Using an existing activity so need to store the existing activity to interaction association
                setActivityData(entityName + "|" + activityId, interaction.id);
                if (entityMappingdata) {
                    setMappingData(entity, interaction, entityMappingdata, entitySchemaName);
                }
            }
            if (Object.hasOwn(associationObj, "description")) {
                // Taks:15784,15787(CIF)
                if (associationObj.description !== null && associationObj.description !== undefined) {
                    if (isInteractionForCustomActivity(interaction)) {
                        if (getDefaultfieldsValues("description", entityName)) {
                            entity.description = associationObj.description;
                        }
                    }
                    else {
                        entity.description = associationObj.description;
                    }
                }
            }
            if (entityMappingdata) {
                setMappingData(entity, interaction, entityMappingdata, entitySchemaName);
            }
            /* add postcall survey activity details to entity to update to CRM phone call activity. */
            const currentInteractioId = getCurrentInteractionId();
            const ssKey = Constant.ssIsCallTransferredToPostCallSurvey + currentInteractioId;
            const isCallTransferredToPostCallSurvey = getStorageItem(ssKey);
            if (entityName == "phonecall" && isCallTransferredToPostCallSurvey == "true") {
                entity.isgc_iscalltransferredtopostcallsurvey = true;
            }
            if (isCreate) {
                if (isInteractionForCustomActivity(interaction)) {
                    if (getDefaultfieldsValues("fromto", entityName)) {
                        setActivityParties(associationObj, entity, entityName);
                    }
                    if (getDefaultfieldsValues("regarding", entityName)) {
                        setRegardingRelationship(associationObj, entity);
                    }
                }
                else {
                    setActivityParties(associationObj, entity, entityName);
                    setRegardingRelationship(associationObj, entity);
                }
                if (getItemBasedOnInteractionId(interaction.id, Constant.ssCreatingActivity) == null) {
                    setItemDataOnInteractionId(true, interaction.id, Constant.ssCreatingActivity);
                    const getRecordsQuery = "?$select=*&$top=1";
                    let allRecords = "";
                    searchAndOpenRecords(entityName, getRecordsQuery, true)
                        .done(function (result) {
                        if (result != null && result != "{}") {
                            allRecords = JSON.parse(result);
                            if (Object.keys(allRecords).length > 0 && Object.keys(allRecords[0]).indexOf("isgc_screenrecordingurl") >= 0) {
                                entity.isgc_screenrecordingurl = entity.isgc_interactionurl.split("/directory/#/engage/admin/interactions/")[0] + "/screen-recording-ui/#/interactions/" + interaction.id;
                            }
                        }
                        upsertActivity(interaction, entityName, activityId, entity, userSelectedActivityOpenCallLog)
                            .done(function (activityData) {
                            if (isCIFV2() && checkInteractionTypeToHoldActivityTab(interaction)) {
                                Global.openActivityTabForExistingActivity = false;
                                checkInteractionState(interaction.isConnected);
                                setInteractionStateForSessionSwitched(interaction.id, interaction.isConnected);
                            }
                            setActivityData(activityData, entity.isgc_interactionid);
                            removeItemDataOnInteractionId(interaction.id, Constant.ssCreatingActivity);
                            deferred.resolve(activityData);
                        })
                            .fail(function (error) {
                            removeItemDataOnInteractionId(interaction.id, Constant.ssCreatingActivity);
                            log("interaction.id", interaction.id, " error: ", error);
                            deferred.reject(error);
                        });
                    })
                        .fail(function (error) {
                        log(returnErrorMessageForApiFailed(error));
                    });
                }
            }
            else {
                if (associationObj.subject != "") {
                    entity.subject = associationObj.subject;
                }
                if (Object.hasOwn(callLog, "selectedContact")) {
                    if (isInteractionForCustomActivity(interaction)) {
                        if (getDefaultfieldsValues("fromto", entityName)) {
                            setActivityParties(associationObj, entity, entityName);
                        }
                    }
                    else {
                        setActivityParties(associationObj, entity, entityName);
                    }
                }
                if (isInteractionForCustomActivity(interaction)) {
                    if (getDefaultfieldsValues("regarding", entityName)) {
                        setRegardingRelationship(associationObj, entity);
                    }
                }
                else {
                    setRegardingRelationship(associationObj, entity);
                }
                //Dynamics does not allow setting subject or description on a received Email interaction
                if (entityName == "email") {
                    if (Object.hasOwn(entity, "description")) {
                        delete entity.description;
                    }
                    if (Object.hasOwn(entity, "subject")) {
                        delete entity.subject;
                    }
                }
                // Subject attribute will be updated/changed on existing record when a callback is made.
                if (interaction.isCallback && interaction.attributes != undefined && activityId == interaction.attributes.is_activityid && JSON.parse(window.participantCustomAttribute).updateCallbackSubject != undefined && !JSON.parse(window.participantCustomAttribute)?.updateCallbackSubject) {
                    delete entity.subject;
                }
                if (shouldPreventLogUpdateAfterWrapUp(interaction, eventName)) {
                    deferred.resolve("isgc_callback|" + activityId);
                }
                else {
                    $.when(upsertActivity(interaction, entityName, activityId, entity, userSelectedActivityOpenCallLog), saveChatTranscriptToActivity(interaction, activityId), saveMessagingTranscriptToActivity(interaction, activityId) /* , handleDisassociation(activityId, entityName) */)
                        .done(function (updateActivityResponse) {
                        setActivityData(updateActivityResponse, interaction.id);
                        if (isCIFV2() && Global.openActivityTabForExistingActivity && interaction.state.toLowerCase() !== "held") {
                            Global.openActivityTabForExistingActivity = false;
                            checkInteractionState(interaction.isConnected);
                            setInteractionStateForSessionSwitched(interaction.id, interaction.isConnected);
                        }
                        deferred.resolve(updateActivityResponse);
                    })
                        .fail(function (error) {
                        log("interaction.id", interaction.id, " error: ", error);
                        deferred.reject(error);
                    });
                }
            }
        }
    }
    catch (ex) {
        deferred.reject(ex.message);
    }
    return deferred.promise();
}
