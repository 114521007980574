import { NButton, NCard, NDivider, NScrollbar } from "naive-ui";
import { defineComponent } from "vue";
export default defineComponent({
    name: "Interaction",
    inject: ["interactionService"],
    components: { NCard, NDivider, NButton, NScrollbar },
    props: {
        interaction: {
            required: true,
            type: Object
        }
    },
    data() {
        return {
            agents: []
        };
    },
    watch: {
        async interaction() {
            var data = await this.fetchInteractionData();
            const notes = this.getWrapUpNotes(data);
        }
    },
    methods: {
        async fetchInteractionData() {
            const data = await this.interactionService.fetchInteractionData(this.interaction.id);
            return data;
        },
        getWrapUpNotes(interactionData) {
            const agentsWithWrapUp = interactionData.participants
                .filter((participant) => {
                return participant.purpose === "agent" && participant.wrapup;
            })
                .map((participant) => {
                return {
                    agentName: participant.name,
                    wrapUpNotes: participant.wrapup.notes,
                    wrapUpCode: participant.wrapup.name
                };
            });
            this.agents = agentsWithWrapUp;
        }
    }
});
