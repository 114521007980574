import { getStorageItem } from "../../functions";
import Constant from "../../variables/constants";
import platformClient from "purecloud-platform-client-v2/dist/node/purecloud-platform-client-v2.js";
export default class InteractionService {
    async fetchInteractionData(interactionId) {
        platformClient.ApiClient.setEnvironment(getStorageItem(Constant.lsClientRegion));
        platformClient.ApiClient.instance.setAccessToken(getStorageItem(Constant.ssAuthToken));
        const apiInstance = new platformClient.ConversationsApi(platformClient.ApiClient);
        const response = await apiInstance.getConversation(interactionId);
        return response;
    }
}
