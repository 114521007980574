import mitt from "mitt";
export default class EventBus {
    emitter = mitt();
    init() {
        setTimeout(() => window.addEventListener("isync-interaction-event", this.eventHandler.bind(this), false), 100);
    }
    eventHandler(event) {
        const e = event.detail;
        switch (e.data.type?.toString().trim().toLowerCase()) {
            case "interactionsubscription":
                this.processInteractionEvents(e);
                break;
        }
    }
    processInteractionEvents(e) {
        let interactionId = "";
        if (e.data.interaction.new) {
            interactionId = e.data.interaction.new.id;
        }
        else {
            interactionId = e.data.interaction.id;
        }
        const category = (e.data.category || "unknown").toString().trim().toLowerCase();
        const event = {
            id: interactionId,
            category: category,
            interaction: e.data.interaction
        };
        this.$emit(`interaction-${category}`, event);
    }
    $on(type, handler) {
        this.emitter.on(type, handler);
    }
    $emit(type, event) {
        this.emitter.emit(type, event);
    }
}
