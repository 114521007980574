import { log } from "./functions";
import { CIFrameworkLoaded, onDocumentReady } from "./root";
import $ from "jquery";
(function () {
    window.addEventListener("CIFInitDone", function () {
        log("CIFInitDone event executed");
        CIFrameworkLoaded();
    });
})();
$(onDocumentReady);
