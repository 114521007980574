import App from "./components/App.vue";
import WEMWidget from "./components/WEMWidget.vue";
import InteractionService from "./interactionDetails/services/interaction.service";
import { createPinia } from "pinia";
import { createApp } from "vue";
function initializeAndMountVueApp(component, mountPoint, pinia, interactionService) {
    const appInstance = createApp(component);
    appInstance.use(pinia);
    appInstance.provide("interactionService", interactionService);
    appInstance.mount(mountPoint);
}
export function loadInteractionDetails() {
    const interactionService = new InteractionService();
    const pinia = createPinia();
    initializeAndMountVueApp(App, "#app", pinia, interactionService);
    initializeAndMountVueApp(WEMWidget, "#wemWidget", pinia, interactionService);
}
