/* eslint-disable prefer-const */
let fieldNameForPrefillData = "";
let openActivityTabForExistingActivity = false;
let tempPerformGlobleSearchObject = null;
let interactionIdsArray = [];
let isCustomActivity = false;
let functionsArray = [];
let searchInCrmFunctionArray = [];
let searchResultArry = [];
let queuesArray = [];
let routingQueuesArray = [];
let externalContactsArray = [];
let peopleArray = [];
let people = false;
let queues = false;
let frameworkContacts = false;
let externalContacts = false;
let transferSessionTabsArray = [];
let flagEmbeddedInteractionWindow = false;
let openedWindow = null;
let embeddedWindowkRequestValue = 0;
const variables = {
    fieldNameForPrefillData,
    openActivityTabForExistingActivity,
    tempPerformGlobleSearchObject,
    interactionIdsArray,
    isCustomActivity,
    functionsArray,
    searchInCrmFunctionArray,
    searchResultArry,
    queuesArray,
    routingQueuesArray,
    externalContactsArray,
    peopleArray,
    people,
    queues,
    frameworkContacts,
    externalContacts,
    transferSessionTabsArray,
    flagEmbeddedInteractionWindow,
    openedWindow,
    embeddedWindowkRequestValue
};
export default variables;
