const LCIDUtils = {
    _cultureInfo: {
        54: { CultureName: "af", LanguageRegion: "Afrikaans" },
        1078: { CultureName: "af-ZA", LanguageRegion: "Afrikaans (South Africa)" },
        28: { CultureName: "sq", LanguageRegion: "Albanian" },
        1052: { CultureName: "sq-AL", LanguageRegion: "Albanian (Albania)" },
        132: { CultureName: "gsw", LanguageRegion: "Alsatian" },
        1156: { CultureName: "gsw-FR", LanguageRegion: "Alsatian (France)" },
        94: { CultureName: "am", LanguageRegion: "Amharic" },
        1118: { CultureName: "am-ET", LanguageRegion: "Amharic (Ethiopia)" },
        1: { CultureName: "ar", LanguageRegion: "Arabic" },
        5121: { CultureName: "ar-DZ", LanguageRegion: "Arabic (Algeria)" },
        15361: { CultureName: "ar-BH", LanguageRegion: "Arabic (Bahrain)" },
        3073: { CultureName: "ar-EG", LanguageRegion: "Arabic (Egypt)" },
        2049: { CultureName: "ar-IQ", LanguageRegion: "Arabic (Iraq)" },
        11265: { CultureName: "ar-JO", LanguageRegion: "Arabic (Jordan)" },
        13313: { CultureName: "ar-KW", LanguageRegion: "Arabic (Kuwait)" },
        12289: { CultureName: "ar-LB", LanguageRegion: "Arabic (Lebanon)" },
        4097: { CultureName: "ar-LY", LanguageRegion: "Arabic (Libya)" },
        6145: { CultureName: "ar-MA", LanguageRegion: "Arabic (Morocco)" },
        8193: { CultureName: "ar-OM", LanguageRegion: "Arabic (Oman)" },
        16385: { CultureName: "ar-QA", LanguageRegion: "Arabic (Qatar)" },
        1025: { CultureName: "ar-SA", LanguageRegion: "Arabic (Saudi Arabia)" },
        10241: { CultureName: "ar-SY", LanguageRegion: "Arabic (Syria)" },
        7169: { CultureName: "ar-TN", LanguageRegion: "Arabic (Tunisia)" },
        14337: { CultureName: "ar-AE", LanguageRegion: "Arabic (U.A.E.)" },
        9217: { CultureName: "ar-YE", LanguageRegion: "Arabic (Yemen)" },
        43: { CultureName: "hy", LanguageRegion: "Armenian" },
        1067: { CultureName: "hy-AM", LanguageRegion: "Armenian (Armenia)" },
        77: { CultureName: "as", LanguageRegion: "Assamese" },
        1101: { CultureName: "as-IN", LanguageRegion: "Assamese (India)" },
        44: { CultureName: "az", LanguageRegion: "Azeri" },
        29740: { CultureName: "az-Cyrl", LanguageRegion: "Azeri (Cyrillic)" },
        2092: { CultureName: "az-Cyrl-AZ", LanguageRegion: "Azeri (Cyrillic, Azerbaijan)" },
        30764: { CultureName: "az-Latn", LanguageRegion: "Azeri (Latin)" },
        1068: { CultureName: "az-Latn-AZ", LanguageRegion: "Azeri (Latin, Azerbaijan)" },
        109: { CultureName: "ba", LanguageRegion: "Bashkir" },
        1133: { CultureName: "ba-RU", LanguageRegion: "Bashkir (Russia)" },
        45: { CultureName: "eu", LanguageRegion: "Basque" },
        1069: { CultureName: "eu-ES", LanguageRegion: "Basque (Basque)" },
        35: { CultureName: "be", LanguageRegion: "Belarusian" },
        1059: { CultureName: "be-BY", LanguageRegion: "Belarusian (Belarus)" },
        69: { CultureName: "bn", LanguageRegion: "Bengali" },
        2117: { CultureName: "bn-BD", LanguageRegion: "Bengali (Bangladesh)" },
        1093: { CultureName: "bn-IN", LanguageRegion: "Bengali (India)" },
        30746: { CultureName: "bs", LanguageRegion: "Bosnian" },
        25626: { CultureName: "bs-Cyrl", LanguageRegion: "Bosnian (Cyrillic)" },
        8218: { CultureName: "bs-Cyrl-BA", LanguageRegion: "Bosnian (Cyrillic, Bosnia and Herzegovina)" },
        26650: { CultureName: "bs-Latn", LanguageRegion: "Bosnian (Latin)" },
        5146: { CultureName: "bs-Latn-BA", LanguageRegion: "Bosnian (Latin, Bosnia and Herzegovina)" },
        126: { CultureName: "br", LanguageRegion: "Breton" },
        1150: { CultureName: "br-FR", LanguageRegion: "Breton (France)" },
        2: { CultureName: "bg", LanguageRegion: "Bulgarian" },
        1026: { CultureName: "bg-BG", LanguageRegion: "Bulgarian (Bulgaria)" },
        3: { CultureName: "ca", LanguageRegion: "Catalan" },
        1027: { CultureName: "ca-ES", LanguageRegion: "Catalan (Catalan)" },
        30724: { CultureName: "zh", LanguageRegion: "Chinese" },
        4: { CultureName: "zh-Hans", LanguageRegion: "Chinese (Simplified)" },
        2052: { CultureName: "zh-CN", LanguageRegion: "Chinese (Simplified, PRC)" },
        4100: { CultureName: "zh-SG", LanguageRegion: "Chinese (Simplified, Singapore)" },
        31748: { CultureName: "zh-Hant", LanguageRegion: "Chinese (Traditional)" },
        3076: { CultureName: "zh-HK", LanguageRegion: "Chinese (Traditional, Hong Kong S.A.R.)" },
        5124: { CultureName: "zh-MO", LanguageRegion: "Chinese (Traditional, Macao S.A.R.)" },
        1028: { CultureName: "zh-TW", LanguageRegion: "Chinese (Traditional, Taiwan)" },
        131: { CultureName: "co", LanguageRegion: "Corsican" },
        1155: { CultureName: "co-FR", LanguageRegion: "Corsican (France)" },
        26: { CultureName: "hr", LanguageRegion: "Croatian" },
        1050: { CultureName: "hr-HR", LanguageRegion: "Croatian (Croatia)" },
        4122: { CultureName: "hr-BA", LanguageRegion: "Croatian (Latin, Bosnia and Herzegovina)" },
        5: { CultureName: "cs", LanguageRegion: "Czech" },
        1029: { CultureName: "cs-CZ", LanguageRegion: "Czech (Czech Republic)" },
        6: { CultureName: "da", LanguageRegion: "Danish" },
        1030: { CultureName: "da-DK", LanguageRegion: "Danish (Denmark)" },
        140: { CultureName: "prs", LanguageRegion: "Dari" },
        1164: { CultureName: "prs-AF", LanguageRegion: "Dari (Afghanistan)" },
        101: { CultureName: "dv", LanguageRegion: "Divehi" },
        1125: { CultureName: "dv-MV", LanguageRegion: "Divehi (Maldives)" },
        19: { CultureName: "nl", LanguageRegion: "Dutch" },
        2067: { CultureName: "nl-BE", LanguageRegion: "Dutch (Belgium)" },
        1043: { CultureName: "nl-NL", LanguageRegion: "Dutch (Netherlands)" },
        9: { CultureName: "en", LanguageRegion: "English" },
        3081: { CultureName: "en-AU", LanguageRegion: "English (Australia)" },
        10249: { CultureName: "en-BZ", LanguageRegion: "English (Belize)" },
        4105: { CultureName: "en-CA", LanguageRegion: "English (Canada)" },
        9225: { CultureName: "en-029", LanguageRegion: "English (Caribbean)" },
        16393: { CultureName: "en-IN", LanguageRegion: "English (India)" },
        6153: { CultureName: "en-IE", LanguageRegion: "English (Ireland)" },
        8201: { CultureName: "en-JM", LanguageRegion: "English (Jamaica)" },
        17417: { CultureName: "en-MY", LanguageRegion: "English (Malaysia)" },
        5129: { CultureName: "en-NZ", LanguageRegion: "English (New Zealand)" },
        13321: { CultureName: "en-PH", LanguageRegion: "English (Republic of the Philippines)" },
        18441: { CultureName: "en-SG", LanguageRegion: "English (Singapore)" },
        7177: { CultureName: "en-ZA", LanguageRegion: "English (South Africa)" },
        11273: { CultureName: "en-TT", LanguageRegion: "English (Trinidad and Tobago)" },
        2057: { CultureName: "en-GB", LanguageRegion: "English (United Kingdom)" },
        1033: { CultureName: "en-US", LanguageRegion: "English (United States)" },
        12297: { CultureName: "en-ZW", LanguageRegion: "English (Zimbabwe)" },
        37: { CultureName: "et", LanguageRegion: "Estonian" },
        1061: { CultureName: "et-EE", LanguageRegion: "Estonian (Estonia)" },
        56: { CultureName: "fo", LanguageRegion: "Faroese" },
        1080: { CultureName: "fo-FO", LanguageRegion: "Faroese (Faroe Islands)" },
        100: { CultureName: "fil", LanguageRegion: "Filipino" },
        1124: { CultureName: "fil-PH", LanguageRegion: "Filipino (Philippines)" },
        11: { CultureName: "fi", LanguageRegion: "Finnish" },
        1035: { CultureName: "fi-FI", LanguageRegion: "Finnish (Finland)" },
        12: { CultureName: "fr", LanguageRegion: "French" },
        2060: { CultureName: "fr-BE", LanguageRegion: "French (Belgium)" },
        3084: { CultureName: "fr-CA", LanguageRegion: "French (Canada)" },
        1036: { CultureName: "fr-FR", LanguageRegion: "French (France)" },
        5132: { CultureName: "fr-LU", LanguageRegion: "French (Luxembourg)" },
        6156: { CultureName: "fr-MC", LanguageRegion: "French (Monaco)" },
        4108: { CultureName: "fr-CH", LanguageRegion: "French (Switzerland)" },
        98: { CultureName: "fy", LanguageRegion: "Frisian" },
        1122: { CultureName: "fy-NL", LanguageRegion: "Frisian (Netherlands)" },
        86: { CultureName: "gl", LanguageRegion: "Galician" },
        1110: { CultureName: "gl-ES", LanguageRegion: "Galician (Galician)" },
        55: { CultureName: "ka", LanguageRegion: "Georgian" },
        1079: { CultureName: "ka-GE", LanguageRegion: "Georgian (Georgia)" },
        7: { CultureName: "de", LanguageRegion: "German" },
        3079: { CultureName: "de-AT", LanguageRegion: "German (Austria)" },
        1031: { CultureName: "de-DE", LanguageRegion: "German (Germany)" },
        5127: { CultureName: "de-LI", LanguageRegion: "German (Liechtenstein)" },
        4103: { CultureName: "de-LU", LanguageRegion: "German (Luxembourg)" },
        2055: { CultureName: "de-CH", LanguageRegion: "German (Switzerland)" },
        8: { CultureName: "el", LanguageRegion: "Greek" },
        1032: { CultureName: "el-GR", LanguageRegion: "Greek (Greece)" },
        111: { CultureName: "kl", LanguageRegion: "Greenlandic" },
        1135: { CultureName: "kl-GL", LanguageRegion: "Greenlandic (Greenland)" },
        71: { CultureName: "gu", LanguageRegion: "Gujarati" },
        1095: { CultureName: "gu-IN", LanguageRegion: "Gujarati (India)" },
        104: { CultureName: "ha", LanguageRegion: "Hausa" },
        31848: { CultureName: "ha-Latn", LanguageRegion: "Hausa (Latin)" },
        1128: { CultureName: "ha-Latn-NG", LanguageRegion: "Hausa (Latin, Nigeria)" },
        13: { CultureName: "he", LanguageRegion: "Hebrew" },
        1037: { CultureName: "he-IL", LanguageRegion: "Hebrew (Israel)" },
        57: { CultureName: "hi", LanguageRegion: "Hindi" },
        1081: { CultureName: "hi-IN", LanguageRegion: "Hindi (India)" },
        14: { CultureName: "hu", LanguageRegion: "Hungarian" },
        1038: { CultureName: "hu-HU", LanguageRegion: "Hungarian (Hungary)" },
        15: { CultureName: "is", LanguageRegion: "Icelandic" },
        1039: { CultureName: "is-IS", LanguageRegion: "Icelandic (Iceland)" },
        112: { CultureName: "ig", LanguageRegion: "Igbo" },
        1136: { CultureName: "ig-NG", LanguageRegion: "Igbo (Nigeria)" },
        33: { CultureName: "id", LanguageRegion: "Indonesian" },
        1057: { CultureName: "id-ID", LanguageRegion: "Indonesian (Indonesia)" },
        93: { CultureName: "iu", LanguageRegion: "Inuktitut" },
        31837: { CultureName: "iu-Latn", LanguageRegion: "Inuktitut (Latin)" },
        2141: { CultureName: "iu-Latn-CA", LanguageRegion: "Inuktitut (Latin, Canada)" },
        30813: { CultureName: "iu-Cans", LanguageRegion: "Inuktitut (Syllabics)" },
        1117: { CultureName: "iu-Cans-CA", LanguageRegion: "Inuktitut (Syllabics, Canada)" },
        60: { CultureName: "ga", LanguageRegion: "Irish" },
        2108: { CultureName: "ga-IE", LanguageRegion: "Irish (Ireland)" },
        52: { CultureName: "xh", LanguageRegion: "isiXhosa" },
        1076: { CultureName: "xh-ZA", LanguageRegion: "isiXhosa (South Africa)" },
        53: { CultureName: "zu", LanguageRegion: "isiZulu" },
        1077: { CultureName: "zu-ZA", LanguageRegion: "isiZulu (South Africa)" },
        16: { CultureName: "it", LanguageRegion: "Italian" },
        1040: { CultureName: "it-IT", LanguageRegion: "Italian (Italy)" },
        2064: { CultureName: "it-CH", LanguageRegion: "Italian (Switzerland)" },
        17: { CultureName: "ja", LanguageRegion: "Japanese" },
        1041: { CultureName: "ja-JP", LanguageRegion: "Japanese (Japan)" },
        75: { CultureName: "kn", LanguageRegion: "Kannada" },
        1099: { CultureName: "kn-IN", LanguageRegion: "Kannada (India)" },
        63: { CultureName: "kk", LanguageRegion: "Kazakh" },
        1087: { CultureName: "kk-KZ", LanguageRegion: "Kazakh (Kazakhstan)" },
        83: { CultureName: "km", LanguageRegion: "Khmer" },
        1107: { CultureName: "km-KH", LanguageRegion: "Khmer (Cambodia)" },
        134: { CultureName: "qut", LanguageRegion: "K'iche" },
        1158: { CultureName: "qut-GT", LanguageRegion: "K'iche (Guatemala)" },
        135: { CultureName: "rw", LanguageRegion: "Kinyarwanda" },
        1159: { CultureName: "rw-RW", LanguageRegion: "Kinyarwanda (Rwanda)" },
        65: { CultureName: "sw", LanguageRegion: "Kiswahili" },
        1089: { CultureName: "sw-KE", LanguageRegion: "Kiswahili (Kenya)" },
        87: { CultureName: "kok", LanguageRegion: "Konkani" },
        1111: { CultureName: "kok-IN", LanguageRegion: "Konkani (India)" },
        18: { CultureName: "ko", LanguageRegion: "Korean" },
        1042: { CultureName: "ko-KR", LanguageRegion: "Korean (Korea)" },
        64: { CultureName: "ky", LanguageRegion: "Kyrgyz" },
        1088: { CultureName: "ky-KG", LanguageRegion: "Kyrgyz (Kyrgyzstan)" },
        84: { CultureName: "lo", LanguageRegion: "Lao" },
        1108: { CultureName: "lo-LA", LanguageRegion: "Lao (Lao P.D.R.)" },
        38: { CultureName: "lv", LanguageRegion: "Latvian" },
        1062: { CultureName: "lv-LV", LanguageRegion: "Latvian (Latvia)" },
        39: { CultureName: "lt", LanguageRegion: "Lithuanian" },
        1063: { CultureName: "lt-LT", LanguageRegion: "Lithuanian (Lithuania)" },
        31790: { CultureName: "dsb", LanguageRegion: "Lower Sorbian" },
        2094: { CultureName: "dsb-DE", LanguageRegion: "Lower Sorbian (Germany)" },
        110: { CultureName: "lb", LanguageRegion: "Luxembourgish" },
        1134: { CultureName: "lb-LU", LanguageRegion: "Luxembourgish (Luxembourg)" },
        1071: { CultureName: "mk-MK", LanguageRegion: "Macedonian (Former Yugoslav Republic of Macedonia)" },
        47: { CultureName: "mk", LanguageRegion: "Macedonian (FYROM)" },
        62: { CultureName: "ms", LanguageRegion: "Malay" },
        2110: { CultureName: "ms-BN", LanguageRegion: "Malay (Brunei Darussalam)" },
        1086: { CultureName: "ms-MY", LanguageRegion: "Malay (Malaysia)" },
        76: { CultureName: "ml", LanguageRegion: "Malayalam" },
        1100: { CultureName: "ml-IN", LanguageRegion: "Malayalam (India)" },
        58: { CultureName: "mt", LanguageRegion: "Maltese" },
        1082: { CultureName: "mt-MT", LanguageRegion: "Maltese (Malta)" },
        129: { CultureName: "mi", LanguageRegion: "Maori" },
        1153: { CultureName: "mi-NZ", LanguageRegion: "Maori (New Zealand)" },
        122: { CultureName: "arn", LanguageRegion: "Mapudungun" },
        1146: { CultureName: "arn-CL", LanguageRegion: "Mapudungun (Chile)" },
        78: { CultureName: "mr", LanguageRegion: "Marathi" },
        1102: { CultureName: "mr-IN", LanguageRegion: "Marathi (India)" },
        124: { CultureName: "moh", LanguageRegion: "Mohawk" },
        1148: { CultureName: "moh-CA", LanguageRegion: "Mohawk (Mohawk)" },
        80: { CultureName: "mn", LanguageRegion: "Mongolian (Cyrillic)" },
        30800: { CultureName: "mn-Cyrl", LanguageRegion: "Mongolian (Cyrillic)" },
        1104: { CultureName: "mn-MN", LanguageRegion: "Mongolian (Cyrillic, Mongolia)" },
        31824: { CultureName: "mn-Mong", LanguageRegion: "Mongolian (Traditional Mongolian)" },
        2128: { CultureName: "mn-Mong-CN", LanguageRegion: "Mongolian (Traditional Mongolian, PRC)" },
        97: { CultureName: "ne", LanguageRegion: "Nepali" },
        1121: { CultureName: "ne-NP", LanguageRegion: "Nepali (Nepal)" },
        20: { CultureName: "no", LanguageRegion: "Norwegian" },
        31764: { CultureName: "nb", LanguageRegion: "Norwegian (Bokm�l)" },
        30740: { CultureName: "nn", LanguageRegion: "Norwegian (Nynorsk)" },
        1044: { CultureName: "nb-NO", LanguageRegion: "Norwegian, Bokm�l (Norway)" },
        2068: { CultureName: "nn-NO", LanguageRegion: "Norwegian, Nynorsk (Norway)" },
        130: { CultureName: "oc", LanguageRegion: "Occitan" },
        1154: { CultureName: "oc-FR", LanguageRegion: "Occitan (France)" },
        72: { CultureName: "or", LanguageRegion: "Oriya" },
        1096: { CultureName: "or-IN", LanguageRegion: "Oriya (India)" },
        99: { CultureName: "ps", LanguageRegion: "Pashto" },
        1123: { CultureName: "ps-AF", LanguageRegion: "Pashto (Afghanistan)" },
        41: { CultureName: "fa", LanguageRegion: "Persian" },
        1065: { CultureName: "fa-IR", LanguageRegion: "Persian" },
        21: { CultureName: "pl", LanguageRegion: "Polish" },
        1045: { CultureName: "pl-PL", LanguageRegion: "Polish (Poland)" },
        22: { CultureName: "pt", LanguageRegion: "Portuguese" },
        1046: { CultureName: "pt-BR", LanguageRegion: "Portuguese (Brazil)" },
        2070: { CultureName: "pt-PT", LanguageRegion: "Portuguese (Portugal)" },
        70: { CultureName: "pa", LanguageRegion: "Punjabi" },
        1094: { CultureName: "pa-IN", LanguageRegion: "Punjabi (India)" },
        107: { CultureName: "quz", LanguageRegion: "Quechua" },
        1131: { CultureName: "quz-BO", LanguageRegion: "Quechua (Bolivia)" },
        2155: { CultureName: "quz-EC", LanguageRegion: "Quechua (Ecuador)" },
        3179: { CultureName: "quz-PE", LanguageRegion: "Quechua (Peru)" },
        24: { CultureName: "ro", LanguageRegion: "Romanian" },
        1048: { CultureName: "ro-RO", LanguageRegion: "Romanian (Romania)" },
        23: { CultureName: "rm", LanguageRegion: "Romansh" },
        1047: { CultureName: "rm-CH", LanguageRegion: "Romansh (Switzerland)" },
        25: { CultureName: "ru", LanguageRegion: "Russian" },
        1049: { CultureName: "ru-RU", LanguageRegion: "Russian (Russia)" },
        28731: { CultureName: "smn", LanguageRegion: "Sami (Inari)" },
        31803: { CultureName: "smj", LanguageRegion: "Sami (Lule)" },
        59: { CultureName: "se", LanguageRegion: "Sami (Northern)" },
        29755: { CultureName: "sms", LanguageRegion: "Sami (Skolt)" },
        30779: { CultureName: "sma", LanguageRegion: "Sami (Southern)" },
        9275: { CultureName: "smn-FI", LanguageRegion: "Sami, Inari (Finland)" },
        4155: { CultureName: "smj-NO", LanguageRegion: "Sami, Lule (Norway)" },
        5179: { CultureName: "smj-SE", LanguageRegion: "Sami, Lule (Sweden)" },
        3131: { CultureName: "se-FI", LanguageRegion: "Sami, Northern (Finland)" },
        1083: { CultureName: "se-NO", LanguageRegion: "Sami, Northern (Norway)" },
        2107: { CultureName: "se-SE", LanguageRegion: "Sami, Northern (Sweden)" },
        8251: { CultureName: "sms-FI", LanguageRegion: "Sami, Skolt (Finland)" },
        6203: { CultureName: "sma-NO", LanguageRegion: "Sami, Southern (Norway)" },
        7227: { CultureName: "sma-SE", LanguageRegion: "Sami, Southern (Sweden)" },
        79: { CultureName: "sa", LanguageRegion: "Sanskrit" },
        1103: { CultureName: "sa-IN", LanguageRegion: "Sanskrit (India)" },
        145: { CultureName: "gd", LanguageRegion: "Scottish Gaelic" },
        1169: { CultureName: "gd-GB", LanguageRegion: "Scottish Gaelic (United Kingdom)" },
        31770: { CultureName: "sr", LanguageRegion: "Serbian" },
        27674: { CultureName: "sr-Cyrl", LanguageRegion: "Serbian (Cyrillic)" },
        7194: { CultureName: "sr-Cyrl-BA", LanguageRegion: "Serbian (Cyrillic, Bosnia and Herzegovina)" },
        12314: { CultureName: "sr-Cyrl-ME", LanguageRegion: "Serbian (Cyrillic, Montenegro)" },
        3098: { CultureName: "sr-Cyrl-CS", LanguageRegion: "Serbian (Cyrillic, Serbia and Montenegro (Former))" },
        10266: { CultureName: "sr-Cyrl-RS", LanguageRegion: "Serbian (Cyrillic, Serbia)" },
        28698: { CultureName: "sr-Latn", LanguageRegion: "Serbian (Latin)" },
        6170: { CultureName: "sr-Latn-BA", LanguageRegion: "Serbian (Latin, Bosnia and Herzegovina)" },
        11290: { CultureName: "sr-Latn-ME", LanguageRegion: "Serbian (Latin, Montenegro)" },
        2074: { CultureName: "sr-Latn-CS", LanguageRegion: "Serbian (Latin, Serbia and Montenegro (Former))" },
        9242: { CultureName: "sr-Latn-RS", LanguageRegion: "Serbian (Latin, Serbia)" },
        108: { CultureName: "nso", LanguageRegion: "Sesotho sa Leboa" },
        1132: { CultureName: "nso-ZA", LanguageRegion: "Sesotho sa Leboa (South Africa)" },
        50: { CultureName: "tn", LanguageRegion: "Setswana" },
        1074: { CultureName: "tn-ZA", LanguageRegion: "Setswana (South Africa)" },
        91: { CultureName: "si", LanguageRegion: "Sinhala" },
        1115: { CultureName: "si-LK", LanguageRegion: "Sinhala (Sri Lanka)" },
        27: { CultureName: "sk", LanguageRegion: "Slovak" },
        1051: { CultureName: "sk-SK", LanguageRegion: "Slovak (Slovakia)" },
        36: { CultureName: "sl", LanguageRegion: "Slovenian" },
        1060: { CultureName: "sl-SI", LanguageRegion: "Slovenian (Slovenia)" },
        10: { CultureName: "es", LanguageRegion: "Spanish" },
        11274: { CultureName: "es-AR", LanguageRegion: "Spanish (Argentina)" },
        16394: { CultureName: "es-BO", LanguageRegion: "Spanish (Bolivia)" },
        13322: { CultureName: "es-CL", LanguageRegion: "Spanish (Chile)" },
        9226: { CultureName: "es-CO", LanguageRegion: "Spanish (Colombia)" },
        5130: { CultureName: "es-CR", LanguageRegion: "Spanish (Costa Rica)" },
        7178: { CultureName: "es-DO", LanguageRegion: "Spanish (Dominican Republic)" },
        12298: { CultureName: "es-EC", LanguageRegion: "Spanish (Ecuador)" },
        17418: { CultureName: "es-SV", LanguageRegion: "Spanish (El Salvador)" },
        4106: { CultureName: "es-GT", LanguageRegion: "Spanish (Guatemala)" },
        18442: { CultureName: "es-HN", LanguageRegion: "Spanish (Honduras)" },
        2058: { CultureName: "es-MX", LanguageRegion: "Spanish (Mexico)" },
        19466: { CultureName: "es-NI", LanguageRegion: "Spanish (Nicaragua)" },
        6154: { CultureName: "es-PA", LanguageRegion: "Spanish (Panama)" },
        15370: { CultureName: "es-PY", LanguageRegion: "Spanish (Paraguay)" },
        10250: { CultureName: "es-PE", LanguageRegion: "Spanish (Peru)" },
        20490: { CultureName: "es-PR", LanguageRegion: "Spanish (Puerto Rico)" },
        3082: { CultureName: "es-ES", LanguageRegion: "Spanish (Spain, International Sort)" },
        21514: { CultureName: "es-US", LanguageRegion: "Spanish (United States)" },
        14346: { CultureName: "es-UY", LanguageRegion: "Spanish (Uruguay)" },
        8202: { CultureName: "es-VE", LanguageRegion: "Spanish (Venezuela)" },
        29: { CultureName: "sv", LanguageRegion: "Swedish" },
        2077: { CultureName: "sv-FI", LanguageRegion: "Swedish (Finland)" },
        1053: { CultureName: "sv-SE", LanguageRegion: "Swedish (Sweden)" },
        90: { CultureName: "syr", LanguageRegion: "Syriac" },
        1114: { CultureName: "syr-SY", LanguageRegion: "Syriac (Syria)" },
        40: { CultureName: "tg", LanguageRegion: "Tajik (Cyrillic)" },
        31784: { CultureName: "tg-Cyrl", LanguageRegion: "Tajik (Cyrillic)" },
        1064: { CultureName: "tg-Cyrl-TJ", LanguageRegion: "Tajik (Cyrillic, Tajikistan)" },
        95: { CultureName: "tzm", LanguageRegion: "Tamazight" },
        31839: { CultureName: "tzm-Latn", LanguageRegion: "Tamazight (Latin)" },
        2143: { CultureName: "tzm-Latn-DZ", LanguageRegion: "Tamazight (Latin, Algeria)" },
        73: { CultureName: "ta", LanguageRegion: "Tamil" },
        1097: { CultureName: "ta-IN", LanguageRegion: "Tamil (India)" },
        68: { CultureName: "tt", LanguageRegion: "Tatar" },
        1092: { CultureName: "tt-RU", LanguageRegion: "Tatar (Russia)" },
        74: { CultureName: "te", LanguageRegion: "Telugu" },
        1098: { CultureName: "te-IN", LanguageRegion: "Telugu (India)" },
        30: { CultureName: "th", LanguageRegion: "Thai" },
        1054: { CultureName: "th-TH", LanguageRegion: "Thai (Thailand)" },
        81: { CultureName: "bo", LanguageRegion: "Tibetan" },
        1105: { CultureName: "bo-CN", LanguageRegion: "Tibetan (PRC)" },
        31: { CultureName: "tr", LanguageRegion: "Turkish" },
        1055: { CultureName: "tr-TR", LanguageRegion: "Turkish (Turkey)" },
        66: { CultureName: "tk", LanguageRegion: "Turkmen" },
        1090: { CultureName: "tk-TM", LanguageRegion: "Turkmen (Turkmenistan)" },
        34: { CultureName: "uk", LanguageRegion: "Ukrainian" },
        1058: { CultureName: "uk-UA", LanguageRegion: "Ukrainian (Ukraine)" },
        46: { CultureName: "hsb", LanguageRegion: "Upper Sorbian" },
        1070: { CultureName: "hsb-DE", LanguageRegion: "Upper Sorbian (Germany)" },
        32: { CultureName: "ur", LanguageRegion: "Urdu" },
        1056: { CultureName: "ur-PK", LanguageRegion: "Urdu (Islamic Republic of Pakistan)" },
        128: { CultureName: "ug", LanguageRegion: "Uyghur" },
        1152: { CultureName: "ug-CN", LanguageRegion: "Uyghur (PRC)" },
        30787: { CultureName: "uz-Cyrl", LanguageRegion: "Uzbek (Cyrillic)" },
        2115: { CultureName: "uz-Cyrl-UZ", LanguageRegion: "Uzbek (Cyrillic, Uzbekistan)" },
        67: { CultureName: "uz", LanguageRegion: "Uzbek (Latin)" },
        31811: { CultureName: "uz-Latn", LanguageRegion: "Uzbek (Latin)" },
        1091: { CultureName: "uz-Latn-UZ", LanguageRegion: "Uzbek (Latin, Uzbekistan)" },
        42: { CultureName: "vi", LanguageRegion: "Vietnamese" },
        1066: { CultureName: "vi-VN", LanguageRegion: "Vietnamese (Vietnam)" },
        82: { CultureName: "cy", LanguageRegion: "Welsh" },
        1106: { CultureName: "cy-GB", LanguageRegion: "Welsh (United Kingdom)" },
        136: { CultureName: "wo", LanguageRegion: "Wolof" },
        1160: { CultureName: "wo-SN", LanguageRegion: "Wolof (Senegal)" },
        133: { CultureName: "sah", LanguageRegion: "Yakut" },
        1157: { CultureName: "sah-RU", LanguageRegion: "Yakut (Russia)" },
        120: { CultureName: "ii", LanguageRegion: "Yi" },
        1144: { CultureName: "ii-CN", LanguageRegion: "Yi (PRC)" },
        106: { CultureName: "yo", LanguageRegion: "Yoruba" },
        1130: { CultureName: "yo-NG", LanguageRegion: "Yoruba (Nigeria)" }
    },
    getCultureName: function (lcid) {
        if (typeof LCIDUtils._cultureInfo[lcid] == "undefined") {
            return "";
        }
        else {
            return LCIDUtils._cultureInfo[lcid].CultureName;
        }
    },
    getLanguageRegion: function (lcid) {
        if (typeof LCIDUtils._cultureInfo[lcid] == "undefined") {
            return "";
        }
        else {
            return LCIDUtils._cultureInfo[lcid].LanguageRegion;
        }
    }
};
export default LCIDUtils;
