/* eslint-disable prefer-const */
let listOfSearchResult = [];
let searchPhonenumber = "";
let activityDescriptionArray = [];
let allResults = [];
const variables = {
    listOfSearchResult,
    searchPhonenumber,
    activityDescriptionArray,
    allResults
};
export default variables;
