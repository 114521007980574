import { closeDialpad, customDialSearch, customDialSearchTxtKeyDownForQueue, customDialSearchTxtKeyUpForQueue, hideListOnFocusOut, initiateCall, onSelectResult, showExistingListOnFocusIn } from "./functions";
import { reloadSidebar } from "./root";
import $ from "jquery";
class Dialpad {
    static intilialzie() {
        $(() => {
            $(".isyncusderrror .reloadButton").on("click", () => reloadSidebar());
            $("#closeButton").on("click", () => closeDialpad(890));
            $("#callButton").on("click", () => initiateCall());
            $("#queue")
                .on("keyup", () => customDialSearchTxtKeyUpForQueue())
                .on("keydown", () => customDialSearchTxtKeyDownForQueue());
            $("#nameOrNumber")
                .on("keyup", (e) => customDialSearch(e))
                .on("focusin", () => showExistingListOnFocusIn())
                .on("focusout", () => hideListOnFocusOut());
            $("#customDialpad").on("click", ".autocompleteitemsdiv", (e) => onSelectResult($(e.currentTarget)));
        });
    }
}
Dialpad.intilialzie();
