import { defineStore } from "pinia";
export const useInteractionStore = defineStore("interactions", {
    state: () => ({
        interactions: []
    }),
    getters: {
        all(state) {
            return state.interactions;
        }
    },
    actions: {
        add(interaction) {
            this.interactions.push(interaction);
        },
        remove(id) {
            const idx = this.interactions.findIndex((interaction) => interaction.id === id);
            this.interactions.splice(idx, 1);
        },
        update(interaction) {
            const idx = this.interactions.findIndex((i) => i.id === interaction.id);
            this.interactions[idx] = interaction;
        }
    }
});
